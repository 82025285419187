import React, { lazy, Suspense } from "react";
import styled from "styled-components";

// import Header from "./header/skins/default";
// import HeaderSkatia from "./header/skins/skatia";
// import SearchWidget from "./search-widget";
// import ArticlesList from "./articles-list/skins/default";
// import GoTo from "./go-to/skins/default";
// import GoToSkatia from "./go-to/skins/skatia";
// import MountainInfo from "./mountain-info/skins/extended";
// import MultimediaGallery from "./multimedia-gallery/skins/default";
// import DragScroll from "./drag-scroll/skins/default";
// import Webcams from "./webcams/skins/default";
// import TextBlock from "./text-block/skins/default";
// import InlineText from "./inline-text/skins/default";
// import Divider from "./divider/skins/default";
// import ClaimBox from "./claim-box/skins/default";
// import EntriesList from "./entries-list/skins/default";
// import ProductList from "./product-list/skins/default";
// import LiftTickets from "./lift-tickets/skins/default";
// import Rentals from "./rentals/skins/default";
// import ExternalCode from "./external-code";
// import Lodging from "./lodging/skins/default";

import { device } from "../constants";

interface ModuleProps {
  config: any;
  name: string;
}

interface ModuleState {
  module: any;
}

export default class Module extends React.Component<ModuleProps, ModuleState> {
  constructor(props) {
    super(props);

    this.state = {
      module: null,
    };
  }

  componentDidMount() {
    let skin = this.props.config.blockSettings.skin || "default";

    if (this.props.name === "inline-text") {
      skin = "spotlio2";
    }

    this.prepareSkin(skin).then((r) => {
      this.setState({ module: r });
    });
  }

  prepareSkin = (skin) => {
    return new Promise((resolve, reject) => {
      if (this.props.name === "header") {
        if (skin === "skatia") {
          const HeaderSkatia = lazy(() => import(`./header/skins/skatia`));
          return resolve(
            <Suspense fallback="...">
              <HeaderSkatia config={this.props.config} />
            </Suspense>
          );
        }
        const Header = lazy(() => import(`./header/skins/default`));
        return resolve(
          <Suspense fallback="...">
            <Header config={this.props.config} />
          </Suspense>
        );
      }
      if (this.props.name === "claim-box") {
        const ClaimBox = lazy(() => import(`./claim-box/skins/default`));
        return resolve(
          <Suspense fallback="...">
            <ClaimBox config={this.props.config} />
          </Suspense>
        );
      }
      if (this.props.name === "search-widget") {
        const SearchWidget = lazy(() => import(`./search-widget`));
        return resolve(
          <Suspense fallback="...">
            <SearchWidget config={this.props.config} />
          </Suspense>
        );
      }
      if (this.props.name === "articles-list") {
        const ArticlesList = lazy(
          () => import(`./articles-list/skins/default`)
        );
        return resolve(
          <Suspense fallback="...">
            <ArticlesList config={this.props.config} />
          </Suspense>
        );
      }
      if (this.props.name === "go-to") {
        if (skin === "skatia") {
          const GoToSkatia = lazy(() => import(`./go-to/skins/default`));
          return resolve(
            <Suspense fallback="...">
              <GoToSkatia config={this.props.config} />
            </Suspense>
          );
        }
        const GoTo = lazy(() => import(`./go-to/skins/default`));
        return resolve(
          <Suspense fallback="...">
            <GoTo config={this.props.config} />
          </Suspense>
        );
      }
      if (this.props.name === "mountain-info") {
        const MountainInfo = lazy(
          () => import(`./mountain-info/skins/extended`)
        );
        return resolve(
          <Suspense fallback="...">
            <MountainInfo config={this.props.config} />
          </Suspense>
        );
      }
      if (this.props.name === "multimedia-gallery") {
        const MultimediaGallery = lazy(
          () => import(`./multimedia-gallery/skins/default`)
        );
        return resolve(
          <Suspense fallback="...">
            <MultimediaGallery config={this.props.config} />
          </Suspense>
        );
      }
      if (this.props.name === "drag-scroll") {
        const DragScroll = lazy(() => import(`./drag-scroll/skins/default`));
        return resolve(
          <Suspense fallback="...">
            <DragScroll config={this.props.config} />
          </Suspense>
        );
      }
      if (this.props.name === "webcams") {
        const Webcams = lazy(() => import(`./webcams/skins/default`));
        return resolve(
          <Suspense fallback="...">
            <Webcams config={this.props.config} />
          </Suspense>
        );
      }
      if (this.props.name === "text-block") {
        const TextBlock = lazy(() => import(`./text-block/skins/default`));
        return resolve(
          <Suspense fallback="...">
            <TextBlock config={this.props.config} />
          </Suspense>
        );
      }
      if (this.props.name === "inline-text") {
        const InlineText = lazy(() => import(`./inline-text/skins/default`));
        return resolve(
          <Suspense fallback="...">
            <InlineText config={this.props.config} />
          </Suspense>
        );
      }
      if (this.props.name === "divider") {
        const Divider = lazy(() => import(`./divider/skins/default`));
        return resolve(
          <Suspense fallback="...">
            <Divider config={this.props.config} />
          </Suspense>
        );
      }
      if (
        this.props.name === "product-list" ||
        this.props.name === "products-list"
      ) {
        const ProductList = lazy(() => import(`./product-list/skins/default`));
        return resolve(
          <Suspense fallback="...">
            <ProductList config={this.props.config} />
          </Suspense>
        );
      }
      if (this.props.name === "entries-list") {
        const EntriesList = lazy(() => import(`./entries-list/skins/default`));
        return resolve(
          <Suspense fallback="...">
            <EntriesList config={this.props.config} />
          </Suspense>
        );
      }
      if (this.props.name === "lift-tickets") {
        const LiftTickets = lazy(() => import(`./lift-tickets/skins/default`));
        return resolve(
          <Suspense fallback="...">
            <LiftTickets config={this.props.config} />
          </Suspense>
        );
      }
      if (this.props.name === "rental") {
        const Rentals = lazy(() => import(`./rentals/skins/default`));
        return resolve(
          <Suspense fallback="...">
            <Rentals config={this.props.config} />
          </Suspense>
        );
      }
      if (this.props.name === "external-code") {
        const ExternalCode = lazy(() => import(`./external-code`));
        return resolve(
          <Suspense fallback="...">
            <ExternalCode config={this.props.config} />
          </Suspense>
        );
      }
      if (this.props.name === "lodging") {
        const Lodging = lazy(() => import(`./lodging/skins/default`));
        return resolve(
          <Suspense fallback="...">
            <Lodging config={this.props.config} />
          </Suspense>
        );
      }
      return resolve(null);
      /*import(`./${this.props.name}/skins/${skin}`).then((importedSkin) => {
        const View = importedSkin;
        return resolve(<View.default config={this.props.config} />);
      });*/
    });
  };

  render() {
    if (!this.props.config.blockSettings) {
      this.props.config.blockSettings = {
        margin: "0 0 0 0",
        padding: "0 0 0 0",
        backgroundColor: "transparent",
        backgroundImage: false,
        backgroundImagePosition: "center center",
        clipPath: false,
      };
    }

    const {
      margin,
      padding,
      backgroundColor,
      backgroundImage,
      backgroundImagePosition,
      clipPath,
      visible,
    } = this.props.config.blockSettings;

    let bgImg = "";
    if (backgroundImage) {
      bgImg = backgroundImage.formats.medium.url;
    }

    const isSearchModule = this.props.name === "search-widget";

    let fPadding = "0 0 0 0";
    if (padding) {
      fPadding = padding;
    }

    let ModuleWrapper;
    ModuleWrapper = styled.div`
      margin: ${margin};
      padding: ${padding};
      background-color: ${backgroundColor || "#ffffff"};
      background-image: url(${bgImg});
      background-position: ${backgroundImagePosition || "bottom left"};
      background-repeat: no-repeat;
      flex: 1;
      ${isSearchModule && `z-index: 1;`}
      ${clipPath &&
      `padding-top: 125px; padding-bottom: 50px; clip-path: polygon(0 0, 100% 100px, 100% 100%, 0% 100%);
            @media only screen and ${device.laptop} {
              padding-top: 75px;
              padding-bottom: 0px; 
              clip-path: polygon(0 0, 100% 50px, 100% 100%, 0% 100%);
            }
          `}
      @media only screen and ${device.laptop} {
        background-size: 100%;
      }
    `;

    if (this.props.name === "header") {
      ModuleWrapper = styled.div`
        margin: ${margin};
      `;
    }

    if (!visible) {
      return null;
    }

    return <ModuleWrapper>{this.state.module}</ModuleWrapper>;
  }
}
